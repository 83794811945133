

const InfoIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3H20C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21ZM5 5V19H19V5H5ZM14 17H11V13H10V11H13V15H14V17ZM13 9H11V7H13V9Z"/>
        </svg>
    );
  };
  
  export default InfoIcon;

