
import React, { useState, useLayoutEffect } from "react"
import "./OrbitLoader.css"

//https://codepen.io/sagarsardiwal/pen/eYOGwZa


function OrbitLoader(props) {


    //   style={{visibility: visible ? 'visible' : 'hidden' }}>
    return (
        <div className="OrbitLoader">
            <div className="orbit">
                <div className="orbit-planet"></div>
            </div>
            <div className="orbit">
                <div className="orbit-planet"></div>
            </div>
            <div className="orbit">
                <div className="orbit-planet"></div>
            </div>
            <div className="central"></div>
        </div>
    )
}


export default OrbitLoader;