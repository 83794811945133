


const ChevronIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"
        preserveAspectRatio="xMidYMin">
            <path d="M20 12l-2.83 2.83 9.17 9.17-9.17 9.17 2.83 2.83 12-12z"/>
        </svg>
    );
  };
  
  export default ChevronIcon;

