import React, { useState, useEffect, useRef }from "react"
import { Menu } from 'primereact/menu';
import './Header.css'
import InfoIcon from "./InfoIcon.js"
import MenuIcon from "./MenuIcon.js";
import BackIcon from "./BackIcon";
import InfoModal from "common/InfoModal/InfoModal.js";



function Header(props) {

  const menu = useRef(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const items = [
    {
        label: 'Signalbox.io',
        url: 'https://www.signalbox.io'
    },
    {
      label: 'Find My Train',
      command:(e) => {
          window.location = "/find-my-train"
      }
    },
    {
        label: 'Share This Train',
        command:(e) => {
            window.location = "/share-this-train"
        }
    } 
]


if (props.homeFormat){
    return (
      <>
        <header className="Header" style={props.style}>

          <span className="header-icon" onClick={(event) => menu.current.toggle(event)}>
            <MenuIcon />
          </span>
          <span className="header-icon" onClick={handleShow}>
          <InfoIcon />
          </span>
        </header>

        {/* <Button label="Show" icon="pi pi-bars" onClick={(event) => menu.current.toggle(event)} aria-controls="popup_menu" aria-haspopup /> */}
        <InfoModal show={show} onHide={handleClose}/> 
        <Menu model={items} popup ref={menu} id="popup_menu" />
        
      </>
    )
}

return (
  <header className="Header" style={props.style}>

    <span className="back-div" onClick={props.onBackClick}>
      <div className="arrow-div"> <BackIcon/> </div> <div className="text-div"><h4 className="back-text"> {props.backText} </h4> </div>
    </span>

    <p></p>   {/* empty paragraph to push back-div over to the left */}
  </header>)


}





export default Header;


