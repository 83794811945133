// custom hook for fetching the train location and iteratively updating it .
import React, {useState, useEffect} from "react"
import { now } from "moment";


const useTrainLocation = function(trainId, coarseTs = 15000){

    const [coarseLocation, setCoarseLocation] = useState({}); // last server location
    const [location, setLocation] = useState({}); // location for plotting (aka marker location)
    const [error, setError] = useState(false);

    // This is where we update the marker location
    useEffect(() => {

        const interpolateLocation = function(){

            if(Object.keys(coarseLocation).length === 0
               || Object.keys(coarseLocation.location).length === 0 
               || Object.keys(coarseLocation.predicted_location).length === 0){
                return {}
            }

                let start_ts = new Date(coarseLocation["ts"]);
                let predicted_ts = new Date(coarseLocation["predicted_ts"]);
                let now = new Date();
                let fol = (now - start_ts)/( predicted_ts - start_ts);
                let lat = coarseLocation.location.lat + (coarseLocation.predicted_location.lat- coarseLocation.location.lat)*fol;
                let lon = coarseLocation.location.lon + (coarseLocation.predicted_location.lon-coarseLocation.location.lon)*fol;
                return {'ts': now.toISOString(), location: {lat: lat, lon: lon}};
         
        }

        const fastTimeout = setTimeout(() => {
            let newLocation = interpolateLocation();
            setLocation(newLocation);
        }, 200);
    
        return () => clearTimeout(fastTimeout) // cleanup function
    
    }, [location])

    // This is where we fetch data from the server. 
    useEffect(() => {

        const fetchData = async function(){
            
            //Waterfall request to fetch current train location, plus location in the future
            setError(false)
            try{
                //fetch train location now.  
                let predict = coarseTs/1000 + 5; //we predict 5 seconds ahead of time
                let url = '/api/train-locations/' + trainId + '?predict=' + predict
                const response = await fetch(url)
                const coarseLocation = await response.json();
                setCoarseLocation(coarseLocation)
                }
            catch(error){
                console.log(error)
                setError(true);
            }
        }

        //TODO make it so that requests don't stack up with slow network connection (for example use a timeout)

        fetchData();
        const slowInterval = setInterval(() => {
            fetchData()
        }, coarseTs);

        return () => clearInterval(slowInterval) // cleanup function

        } 
    , [])

    return [location, error]
}


export default useTrainLocation;


