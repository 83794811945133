// This drawing represents the stops
import React, { useEffect, useState} from "react"
import "./StopsDrawing.css"


const StopsDrawing = (props) => {

    const [height, setHeight] = useState(2); //height in pixels.
    const [width, setWidth] = useState(2); //height in pixels.
    
    const tickWidth = 15;
    const yOffset = 16; //number of pixels before schedule line starts. 
    const tickOffset = -4;


    const locationDot = function(yPos){

        return (

            <>
            <filter id="blurMe" x="-50%" y="-50%" width="40" height="40">
                <feGaussianBlur in="SourceGraphic" stdDeviation="2" />
            </filter>

            <svg id="location-dot" x={width/2 - 20} y={yPos-20} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="20" cy="20" r="17" fill="#009B83" fillOpacity="0.9" filter="url(#blurMe)"/>
                <circle cx="20" cy="20" r="15" fill="#009B83" fillOpacity="0.8"/>
                <circle cx="20" cy="20" r="8.03571" fill="#003F56"/>
            </svg>
            </>
        )
    }

    const getTick = function(yTick, key){

        return <line key={key} x1={width/2} y1={yTick + tickOffset} x2={width/2+tickWidth} 
        y2={yTick + tickOffset} stroke="#003F56"
        strokeWidth="6" > </line>
    }

    const getTerminal = function(yTick, key){

        return (
        <svg key={key} x={width/2 - 15} y={yTick-15} width="30" height="30">
            <rect width="30" height="30" fill="#003F56"/>
            <rect x="10" y="10" width="10" height="10" fill="#FFFFCB"/>
        </svg>
        )
    }

    function createMarkers(){
        var markers = [];
        for (var i = 0; i < props.numberStops; i++) {
            let yTick = (i * height/props.numberStops)+ yOffset;
            let marker;
            if (i==0 || i == props.numberStops-1){
                marker = getTerminal(yTick, "term"+i);
            }else{
                marker = getTick(yTick, "stop"+i);
            }
             
            markers.push(marker)
        }
    return markers
    }

    // width and height of drawing in pixels. 
    useEffect(() => {
        let height = document.getElementById('stops-drawing-div').clientHeight;
        setHeight(height);
        let width = document.getElementById('stops-drawing-div').clientWidth;
        setWidth(width);
        
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    return (

      <div className="StopsDrawing" id="stops-drawing-div">
            <svg className="stops-drawing"
                xmlns="http://www.w3.org/2000/svg"
                viewBox={`0 0 ${width} ${height}`}
                preserveAspectRatio="xMidYMid">
                {/* vertical line */}
                <line x1={width/2} y1={yOffset} x2={width/2} y2={height*(1-1/props.numberStops) + yOffset} stroke="#003F56"
                strokeWidth="6"> </line> 
     
                {createMarkers()}
                {locationDot(props.dotPosition * (height - (height/props.numberStops))+yOffset)}
            </svg>
      </div>
    )
        


    }
  export default StopsDrawing;

