// Custom hook to obtain location and fetch data from Signalbox
import React, {useState, useEffect, Fragment, useRef} from "react"
import { useHistory, withRouter} from 'react-router-dom';

import SnapshotLocation from "../geolocation/SnapshotLocation.js"



const useSignalbox = function(onCompleted = ()=>{}, onError = ()=>{}){

    const onCompleteRef = useRef();
    onCompleteRef.current = onCompleted;
    const onErrorRef = useRef();
    onErrorRef.current = onError;
    const destinationRef = useRef();


    let history = useHistory();
      
    const [trains, setTrains] = useState([]);
    const [isError, setError] = useState(false)
    const [detecting, setDetecting] = useState(false);
 


    const startFetch = function(destination = null){

        setDetecting(true);
        destinationRef.current = destination;
        snapshotLocation.acquireLocation(onPositionSuccess, onPositionError)
        }
      
      const stopFetch = () => {
          snapshotLocation.stop();
          setDetecting(false);
      }
      
    let snapshotLocation = SnapshotLocation.getInstance();

    const onPositionSuccess = function(position){
      var parsedPosition = SnapshotLocation.positionParse(position);
      fetchSignalbox(parsedPosition)
    }

    const onPositionError = function(error){
      // alert(errorCode);

      snapshotLocation.stop();
      
      let hint = null

      let message = "An unknown error occurred";
      switch (error.code) {
        case error.PERMISSION_DENIED:
            message = "Permission for location denied"
            hint = `Please change the browser permissions, reload 
                             the page, and try again.`
        case error.POSITION_UNAVAILABLE:
            message = "Location is unavailable"
            hint = `Please change the browser permissions, reload 
                            the page, and try again.`
            break;
        case error.TIMEOUT:
            message = "Unable to obtain a location"
            hint = `This may be due to poor reception of signals. 
                              Wait a moment and try again.`
            break;
        case error.UNKNOWN_ERROR:
            message = "An unknown error occurred"
            hint = `We are not sure why an error occured. Please change the permissions 
                            in your browser, reload the page and try again.`
            break;
    }
        setError(true);
        onErrorRef.current({error: message, hint: hint});
    }

    const fetchSignalbox = async (parsedPosition)=> {

      if(destinationRef.current!== null){
        parsedPosition['destination'] = destinationRef.current;   // add destination crs
      }

      fetch('/api/detect/', {
              method: 'POST', 
              headers: { 'Content-Type': 'application/json'},
              body: JSON.stringify(parsedPosition),
              }).then(response => {
                // alert(JSON.stringify(response));
                const data = response.json();
                return data;
              }).then(data => {
                const trains = data.trains; //array of trains
                setTrains(trains)
                // console.log(JSON.stringify(trains));
                setDetecting(false);
                onCompleteRef.current(trains);
              }
              ).catch(()=>{

                setError(true);
                let message = "There is no internet connection"
                let hint = `Please try again when your data connection improves.`
               
                onErrorRef.current( {error: message, hint: hint})
              })
    }

      return [trains, detecting, isError, startFetch, stopFetch, setError]
}


export default useSignalbox;

