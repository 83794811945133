/** This isi the container for displaying results from lots of trains */
import React from "react"
import NoResultIcon from "./NoResultIcon.js"

import "./NoResult.css"



function NoResult(props) {


return (// more than one train result
        <div className="NoResult">
          <div className="no-result-icon">
          <NoResultIcon />
          </div>
          <h3> {props.message ? props.message.error :  "We couldn't detect your train"} </h3>
          {props.message && props.message.hint?
              <h4> {props.message.hint} </h4>
              : null
          }
            <button className="button-dark" onClick={props.onTryAgainClick}> Try Again </button> 
          
        </div>
    )
}

export default NoResult;
