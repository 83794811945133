import React, { useState, useEffect, Fragment} from "react"
import * as moment from 'moment'
import StopsDrawing from "common/StopsDrawing/StopsDrawing.js"
import './Stops.css'


function Stops(props) {

  // this is the position of the ticks for the stops (relative to top of div). 
  //console.log(props.stops)
  /**
    * Creates delay string
    */
  const actualTime = function (stop) {
    return  (stop.actual_departure === null) ? moment(stop.actual_arrival) : moment(stop.actual_departure);
  }

  const scheduledTime = function(stop){
    return (stop.departure === null) ? moment(stop.arrival) : moment(stop.departure);
  }

  const isDelayed = function(stop){
  
    let mins =  actualTime(stop).diff(scheduledTime(stop), 'minutes')
    // actual.format("HH:mm")
    return mins > 0
  }

  const isAtStation = function(stop){

    let currentTime = moment();
    let actualArrival = (stop.actual_arrival === null) ? null : moment(stop.actual_arrival);
    let actualDeparture = (stop.actual_departure === null) ? null : moment(stop.actual_departure );

    //check whether at origin
    if(actualArrival === null && currentTime < actualDeparture ){
      return true
    }
    //check whether arrived at destination
    if(actualDeparture === null && currentTime > actualArrival ){
      return true
    }
    return currentTime.isBetween(actualArrival, actualDeparture);

  } 

  /**
   * 
  **/
  const dotPosition = function () {

    //check at stop and return index of stop. 
    let index = props.stops.findIndex(isAtStation);
    let currentTime = moment();

    if(index !== -1){
      return index/(props.stops.length-1);
    }

    for (let i = 0; i < (props.stops.length - 1); i++) {
      let lastStop = props.stops[i]
      let nextStop = props.stops[i+1]
      let lastDeparture = moment(lastStop.actual_departure);
      let nextArrival = moment(nextStop.actual_arrival);
      if(currentTime.isBetween(lastDeparture, nextArrival)){

        var betweenStops = moment.duration(nextArrival.diff(lastDeparture));
        var sinceLastStop = moment.duration(currentTime.diff(lastDeparture));
        var proportionComplete = sinceLastStop.asSeconds()/betweenStops.asSeconds() ;
        // alert(proportionComplete)
        return  i/(props.stops.length-1) + proportionComplete/(props.stops.length-1);
        //(proportionComplete * (1/props.stops.length)
      }
      }
      return -1
    } 

  if (props.loading) {
    return (<div className="Stops"> Loading... </div>)
  }

  if (!props.stops) {
    return (<div className="Stops"> No train </div>)
  }

  return (
    <div className="Stops">

      {/*This adds schedule schematic*/}
      <div className="left-column" style={{ "gridRowStart": "1",  "gridRowEnd": String(props.stops.length + 1) }} >
        <StopsDrawing numberStops={props.stops.length} dotPosition={dotPosition()}/>
      </div>

      {/* {alert("dot position: " + dotPosition()*props.stops.length)} */}

      {/* This adds the text */}
      {props.stops.map(
        (stop, index) => (

          <Fragment key={"g" + index}>

            <div  id={Math.round(dotPosition() * props.stops.length -1)==index ? "nearest-stop": null} className="middle-column" style={{ "gridRow": index+1 }}>
              <h3> {stop.name} </h3>
              <h4 > Platform {stop.platform} </h4>
            </div>
            <div className="right-column" style={{ "gridRow": index+1}}>
              <h3 > {scheduledTime(stop).format("HH:mm")} </h3>
              <h4 className={isDelayed(stop) ? 'delayed': "" }> 
                  {isDelayed(stop) ?  actualTime(stop).format("HH:mm") : "On time"}
             
              </h4>

            </div>
          </Fragment>
        )
      )}
    </div>
  );
}

export default Stops;


