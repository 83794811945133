// custom hook that is used to track a marker. 

import React, { useState, useEffect } from "react"
import { TRANSITION_EVENTS } from 'react-map-gl';
import mapboxgl from 'mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

const useTrackMarker = function (location, map, verticalOffset = 0.3, centerRadius=0.6) {

  const [trackViewport, setTrackViewport] = useState({
    width: 400,
    height: 400,
    latitude: 53.4871,
    longitude: -1.2424,
    zoom: 14
  }); // last server location

  // This effect is used to update the track viewport.
  useEffect(() => {

    const getTrackViewport = function () {

                    if (Object.keys(location).length === 0 ||
                      Object.keys(location.location).length === 0 ||
                      !map) {
                      return
                    }
                    // console.log(bounds)
                    let loc = new mapboxgl.LngLat(location.location.lon, location.location.lat);
                    
                    let mapHeight = map.getBounds().getSouthEast().distanceTo(map.getBounds().getNorthEast());
                    let mapWidth = map.getBounds().getSouthEast().distanceTo(map.getBounds().getSouthWest());
                    
                    let radius = Math.min(mapHeight, mapWidth)/2 * centerRadius;
                    let innerContains = map.getCenter().toBounds(radius).contains(loc)
                    let contains = map.getBounds().contains(loc)
                    
                    if (!contains || !innerContains) {

                        var duration = (!contains) ? 0 : 5000; // duration is reduce to zero to snap map to correct location.
                        let offsetMeters = mapHeight/2 * verticalOffset; // vertical offset from centre 
                        let offsetLat = 360 * (offsetMeters / (6000000.0 * 6))
                      
                      return {
                        latitude: location.location.lat - offsetLat,
                        longitude: location.location.lon,
                        transitionDuration: duration,
                        transitionInteruption: TRANSITION_EVENTS.UPDATE, //https://visgl.github.io/react-map-gl/docs/api-reference/interactive-map
                        zoom: 13
                      }
                    }
                  }

          const trackViewport = getTrackViewport()
          setTrackViewport(trackViewport);
                 
          return 

  }
    , [location] )

  return trackViewport

}


export default useTrackMarker;


