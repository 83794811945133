


const NoTrainIcon = () => {
    return (
        <svg width="110" height="110" viewBox="0 0 110 110" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle opacity="0.6" cx="55" cy="55" r="55" fill="#A23F44"/>
            <circle cx="55" cy="55" r="31" fill="#F6F6F6"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M54.9999 13.3333C78.0118 13.3333 96.6666 31.9881 96.6666 54.9999C96.6666 78.0118 78.0118 96.6666 54.9999 96.6666C31.9881 96.6666 13.3333 78.0118 13.3333 54.9999C13.3333 31.9881 31.9881 13.3333 54.9999 13.3333ZM50.8333 71.6666H59.1666V79.9999H50.8333V71.6666ZM54.9999 29.9999C57.3011 29.9999 59.1666 31.8654 59.1666 34.1666V59.1666C59.1666 61.4678 57.3011 63.3333 54.9999 63.3333C52.6987 63.3333 50.8333 61.4678 50.8333 59.1666V34.1666C50.8333 31.8654 52.6987 29.9999 54.9999 29.9999Z" fill="#A23F44"/>
        </svg>
    );
  };
  
  export default NoTrainIcon;

