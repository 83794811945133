
import React, {useState, useEffect} from "react"

import RippleLoader from "common/RippleLoader/RippleLoader.js"
import { AutoComplete } from 'primereact/autocomplete';
import "./DestinationStation.css"
import stationsObj from './Stations.js';


function DestinationStation(props) {


    const [stations, setStations] = useState(Object.keys(stationsObj));
    const [selectedStation, setSelectedStation] = useState(null);
    const [filteredStations, setFilteredStations] = useState(null);

    const searchStation = (event) => {
        setTimeout(() => {
            let _filteredStations;
            if (!event.query.trim().length) {
                // _filteredStations = [...stations];
            }
            else {
                _filteredStations = stations.filter((station) => {
                    // return station.toLowerCase().startsWith(event.query.toLowerCase());
                    return station.toLowerCase().includes(event.query.toLowerCase());
                });
            }
            setFilteredStations(_filteredStations);
        }, 150);
    }

    useEffect(() => {
        //this is a hack to turn off spellcheck of the input (because of a ommision/but in prime react )
        document.getElementById("station-autocomplete").setAttribute("spellcheck", false);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onChange = function(event){
            setSelectedStation(event.value);
        if (event.value in stationsObj){
                    
            // alert("Selected station: " + event.value)
            let destination = stationsObj[event.value].toLowerCase();
            console.log('Destination is set as: ' + destination);
            props.setDestination(destination)
            props.startSignalbox(destination);            
        }
    }

    if (props.isError){
            return  ( 
            <div className="DestinationStation">
                <div className="center-div"> 
                    <h3> Unable to obtain location </h3>
                </div>
            </div>)
        }

    if (props.detecting){  
        return (

            <div className="DestinationStation">            
        <div className="center-div"> 
            <RippleLoader/>
            <h3 className="detecting-text"> Detecting your train </h3> 
            </div>
            </div>
            )
        }

    return (

        <>
        <div className="DestinationStation">
           
            <div className="center-div"> 
                <h2> Share This Train </h2>
                <h3> Share your train and arrival time </h3>
                <AutoComplete value={selectedStation} suggestions={filteredStations} completeMethod={searchStation} 
                 onChange={onChange} spellcheck="false" inputId="station-autocomplete"
                 placeholder="Enter your destination station"/>
            </div>
        </div>

        </>

    )
}
  
export default DestinationStation;