import React, { useState, useEffect} from 'react';
import { useParams } from "react-router-dom"
import './Train.css'
import Stops from 'common/Stops/Stops.js'


function Train(props) {


  const { rid } = useParams();

  // let rid = props.match.params.rid;
  const [train, setTrain] = useState({});
  const [loading, setLoading] = useState(false);
  const [isError, setError] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  }
  const handleShow = () => {
    setShow(true);
  }

  useEffect(async ()=> {
    setLoading(true)
    try{
      const response = await fetch('/api/trains/' + rid )
      const train = await response.json();
      if (!response.ok){
        throw(new Error())
      }
      setTrain(train)
    }catch{
      setError(true);
    }
    setLoading(false);
  }, [])


  if(isError){
    return (
      <div className="Train">
        <h3>Unknown error</h3>
      </div>
  )
  }
  
  if(loading){
    return (
        <div className="Train">
          <div className="container-div">
            <div className="loading-div">
              <h3>Loading...</h3>
            </div>
          </div>
        </div>
    )
  }
  return (
    <div className="Train">

      <div className="container-div">

        <div className="summary-div"> 
          <h3> {train.origin_name} to <br/>
          {train.destination_name}</h3>
          <h4> {train.train_operator} </h4>
        </div> 
       
        <div className="stops-div">
          <Stops stops={train.stops}/>
        </div>

        <div className="footer-div">
            <button className = "button-dark" onClick={props.onClick} > Return to search </button>
        </div>

      </div>

    </div>

  );
}

export default Train;
