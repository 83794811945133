import React, { useState, useEffect, Fragment } from "react"
import { useHistory, withRouter } from 'react-router-dom';
import './FindMyTrain.css'
// import Detect from "./Detect.js"
import Detect from "apps/find_my_train/Detect/Detect.js"
import Train from "apps/find_my_train/Train/Train.js"
import TrainList from "common/TrainList/TrainList.js"
import NoResult from "common/NoResult/NoResult.js"
import useSignalbox from "common/useSignalbox.js";
import Header from "common/Header/Header.js"

import {
  Router, // needed because of this https://reactforyou.com/history-push-is-only-changing-the-url-not-rendering-component-react-fix/
  Switch,
  Route,
  useRouteMatch,
  Redirect
} from "react-router-dom";



function FindMyTrain(props) {

  let history = useHistory();
  let { path, url } = useRouteMatch();
  const [errorMessage, setErrorMessage] = useState(null)

  const onCompleted = function (trains) {
    if (trains.length === 0) {
      history.push('/find-my-train/no-train');
    }
    if (trains.length === 1) {
      history.push('/find-my-train/trains/' + trains[0].rid);
    }
    if (trains.length >= 2) {
      history.push("/find-my-train/train-list");
    }
  }

  const onError = function(message){
    // alert(message);
    setErrorMessage(message);
    history.push(`${path}/error`);
  }

  const [trains, detecting, isError, startFetch, stopFetch, setError] = useSignalbox(onCompleted, onError)

  const returnHome = () => {
    stopFetch();
    setError(false); // have to reset error
    history.push(`${path}`);
  }

  const backToResults = () => {
    stopFetch();
    history.push(`${path}/train-list`);
  }

  const startSignalbox = () => {
    startFetch();
  }

  const onTrainSelect = function (rid) {
    history.push(`${path}/trains/${rid}`);
  }

  return (

    <div className="FindMyTrain">

      <Router history={history}>
        <Switch>

        <Route exact path={`${path}/error`}>
              <Header onBackClick={returnHome} homeFormat={!detecting} backText="Back to search"/>
              <NoResult onTryAgainClick={returnHome} message={errorMessage}/>
          </Route>
          <Route exact path={`${path}/`}>
            <Header onBackClick={returnHome} homeFormat={!detecting} backText="Back to search" />
            <Detect onStartClick={startSignalbox} detecting={detecting} isError={isError} />
          </Route>
          <Route exact path={`${path}/no-train`}>
            <Header onBackClick={returnHome} homeFormat={false} backText="Back to search" />
            <NoResult onTryAgainClick={returnHome} />
          </Route>
          <Route exact path={`${path}/train-list`}>
            <Header onBackClick={returnHome} homeFormat={false} backText="Back to search" style={{ backgroundColor: "#F6F6F6" }} />
            {trains.length == 0 ? <Redirect to={`${path}/`} /> : 
            <TrainList trains={trains} onTrainSelect={onTrainSelect} trainSelectText={"View this train"} />}
          </Route>
          <Route exact path={`${path}/trains/:rid`}>
            <Header onBackClick={backToResults} homeFormat={false} backText="Back to search" style={{ backgroundColor: "none"}} />
            <Train onClick={returnHome}/>
          </Route>
        </Switch>
      </Router>
    </div>

  )
}

export default withRouter(FindMyTrain);