
import React, {useState, useEffect} from "react"
import "./Detect.css"
import OrbitLoader from "common/OrbitLoader/OrbitLoader.js"

function Detect(props) {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    if (props.isError){
            return  ( 
            <div className="Detect">
                <div className="center-div"> 
                <h3> Unable to obtain location </h3>
                </div>
            </div>)
        }

    if (props.detecting){  
        return (

        <div className="Detect">
            <div className="center-div"> 
            <div className="loader-div"> 
                <OrbitLoader/>
            </div>
            <h3> Detecting your train </h3> 
            </div>
         </div>
            )
        }

    return (
        <div className="Detect">
            <div className="center-div"> 
                <button className="button-light" onClick={props.onStartClick}> Find My Train </button> 
            </div>
        </div>
    )
}


export default Detect;