// Collapsable card for eta sharing. 

import React, {useState, useEffect, useRef, useReducer} from "react"
import moment from 'moment';
import { Toast } from 'primereact/toast';
import "./EtaSharing.css"
import StopsModal from 'common/StopsModal/StopsModal.js'
import ChevronIcon from "./ChevronIcon";


function EtaSharing(props) {

  // eta sharing.
  const toast = useRef(null);

  const [expanded, toggleExpanded] = useReducer(expanded => !expanded, true); // whether collapsed. 

  const [show, setShow] = useState(false);
  const hideStopsModal = () => {
    setShow(false);
  }
  const showStopsModal = () => {
    setShow(true);
  }

  //loop through stops to return the stop with given crs
  const getStop = function(stops, crs){
    for (let i = 0; i < (stops.length); i++) {
      //  console.log(stops[i])
        if(stops[i].actual_arrival !== null && stops[i].crs.toLowerCase() === crs.toLowerCase()){
          // console.log(JSON.stringify(stops[i]))
          // console.log(stops[i].actualArrival)
          return stops[i]
        }
    }
    return null
  }

  const stop = getStop(props.train.stops, props.crs)
  let actualArrival = moment(stop.actual_arrival);

  let duration = moment.duration(actualArrival.diff(moment.now()))

  // let duration = Math.round(moment.duration(actualArrival.diff(moment.now())).asMinutes());

  const onClick = () => {
    //this is what happens when share is clicked. 

    let stationName = stop.name;
    let arrivalTimeStr = moment(stop.actual_arrival).format("HH:mm");
    var shareUrl = window.location.href;

    if (navigator.share) {
      navigator.share({
          title: 'Signalbox ETA',
          text: 'On my way to ' + stationName + '. My estimated arrival time is ' + arrivalTimeStr + '. Check for live updates.',
          url: shareUrl,
        })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
        return
      }
      // runs if navigator doesn't support sharing (copies link)
      console.log('Share not supported on this browser, do it the old way.');

      toast.current.show({severity:'info', summary: 'Info Message', detail:'Share tray not supported on this browser. \
            Web page link has been copied for sharing manually', life: 3000});
      navigator.clipboard.writeText(shareUrl)
  }

    const rotate = expanded ? "rotate(90deg)" : "rotate(0)"

    
    const etaText = function (duration){
      //Return ETA text
      let minutes = Math.ceil(duration.asMinutes());
      const humanizeDuration = require("humanize-duration");
      let humanizedText = humanizeDuration(duration.asMilliseconds(), { units: ["h", "m"], round: true})

      if(minutes === 0){
        return (<> Just arrived </>)
      }
      if(minutes > 0){
          return <> Arriving in {humanizedText} </>
          // return (<>  Arriving {duration.humanize(true)} </>)
      }
      return (<> Arrived {humanizedText} ago </>) 
    }
    
    return (

      <>
        
        <div className="EtaSharing">

            <div className="header">
             
            <div className="top-line" onClick={toggleExpanded}>
              <h2>{stop.name} </h2>  
              <div className="chevron" style={{ transform: rotate, transition: "all 0.1s linear" }}>
                <ChevronIcon/>
              </div>
            </div>
            
              <h3>
                  {etaText(duration)}
              </h3> 
            </div>
            {(expanded)?
            (
             <div className="main-content">
              <h4> {props.train.origin_name} to {props.train.destination_name} <a className="view-stops" onClick={showStopsModal}>View Stops</a> </h4>        
              <button className = "button-light" onClick={onClick} > Share this train </button>
            </div>
            ): null}


        </div>

          <Toast ref={toast}  position="top-left"/>    
          <StopsModal show={show} onHide={hideStopsModal} rid={props.train.rid} /> 
      </>
    )
}


export default EtaSharing;





