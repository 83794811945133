import React, { useState, useEffect } from 'react';
import { Link,
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import './App.css';
import FindMyTrain from 'apps/find_my_train/FindMyTrain/FindMyTrain.js'
import ShareThisTrain from 'apps/share_this_train/ShareThisTrain/ShareThisTrain.js'
import TrainMap from 'apps/share_this_train/TrainMap/TrainMap.js'



function App(props) {


  return (

    <div className="App">

      <Router>

          <Switch>
          
          <Route exact path="/">
              <div className="menu">
                <Link to="/find-my-train">
                  <button className="button-light"> Find My Train </button>
                </Link>
                <Link to="/share-this-train">
                  <button className="button-light"> Share This Train </button>
                </Link>
              </div>
            </Route>

            <Route path="/find-my-train">
              <FindMyTrain />
            </Route>
            <Route path="/share-my-train">
                  <Redirect to='/share-this-train'  />
            </Route>
            <Route path="/share-this-train">
              <ShareThisTrain />
            </Route>
            <Route exact path="/embed/:trainId">
               <TrainMap />
            </Route> 
          
          </Switch>
      
      
      </Router>
    </div>  
    )  
  }

export default App;
