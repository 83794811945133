// This modal shows information about the app and Signalbox. 
// For the first iteration we should have the same modal for all the demo apps. 

import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import './InfoModal.css'


// TODO make it so that it is also possible to parse the train information (rather than the rid) in the props. 
// This will allow train information to only be obtained once.  

function InfoModal(props) {

  const header = (<div className="info-header"> <h2> Signalbox </h2> </div>)

  const footer = (  <div className="info-footer">
  <button className="button-dark" onClick={props.onFooterClick ? props.onFooterClick: props.onHide}> {props.footerText ? props.footerText : "Close"} </button>
  {/* <button className="button-dark" onClick={scrollToDot}> "Scroll to dot" </button> */}
 </div>)


  return (

  
        <Dialog className="InfoModal" header={header} footer={footer} visible={props.show}  
        onHide={props.onHide}
        position="bottom"
        blockScroll={true}
        dismissableMask={true}
        closable={true}
        focusOnShow={false}
        >
      {/* <Modal show={props.show} onHide={props.handleClose}> */}
  
            <div className="body"> 
            
            <h3>About</h3>

                <p>
                    <br/>
                    This app uses Signalbox API, which combines real-time transport information with 
                    sophisticated GPS and mapping technology to geolocate devices and match them with trains on the rail network in Great Britain.
                    <br/>
                    <br/>
                </p>
        
            <h3> Cookies and privacy</h3>

                <p>
                    <br/>
                    This app uses cookies to allow us to remember that you have visited us before. This allows us to improve the
                    quality of our train estimates. Although we use cookies to identify a user's device, we do not use cookies to
                    store any personal information, such as name or email address.
                    <br/> 
                    <br/>
                    If you continue using this app, we will assume that you are happy for cookies to be used in the manner
                    described.
                    <br/>
                    <br/>
                </p>
                
            <h3> Licensing information </h3>
        
            <p>
                <br/>
                Contains Information of Network Rail Infrastructure Limited licensed under the following <a
                        href="http://www.networkrail.co.uk/data-feeds/terms-and-conditions">licence.</a> Powered by <a
                        href="http://www.nationalrail.co.uk/">National Rail Enquiries.</a>
                <br/> 
                <br/>
            </p>

            <h3>Troubleshooting</h3>
                <p>
                <br/>
                If trains are not displaying or updating, try refreshing the page. Also, make sure you have a good internet
                connection.
                <br/>
                <br/>
                    For a "Location Unavailable" response, try updating your browser to the most recent version or, alternatively,
                    try viewing on a newer device. In addition, in the settings of your browser, make sure JavaScript is enabled and
                    geolocation permission is given.
                <br/>

                <br/>
                </p>

            </div> 

      </Dialog>

     
    
  

  );
}

export default InfoModal;
