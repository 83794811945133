/** This isi the container for displaying results from lots of trains */

import React, { useState, useEffect }from "react"
import { useHistory, Redirect} from 'react-router-dom';
import StopsModal from 'common/StopsModal/StopsModal.js'
import "./TrainList.css"


function TrainList(props) {

    let history = useHistory();
    const a = {}
    props.trains.map( (train)=>{
        a[train.rid] = false;
    })
    const [show, setShow] = useState(a);
    
    const onHide = (rid) => {
        const newShow = Object.assign({}, show);
        newShow[rid] = false;
        setShow(newShow);
    }
    const handleShow = (rid) => {
        const newShow = Object.assign({}, show);
        newShow[rid] = true;
        setShow(newShow);
    }

return (// more than one train result
 
    <> 
          <button style={{height: "1px", width: "1px"}} autoFocus></button>
        <div className="TrainList">
         {/* this has been added to make sure the first button isn't in focus on load for iOS*/}
            {props.trains.map(
            (train) => (
            
                    <div className="train-summary" key={"t"+train.rid} >
                        <h2> {train.origin_name} to <br/> 
                        {train.destination_name} </h2>
                        <h3> {train.train_operator} </h3>
                        <svg width="100%" height="1" viewBox="0 0 314 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M314 0L0 0L0 1L314 1V0Z" fill="#E5E5E5"/>
                        </svg>

                        <div className="view-stops" onClick={() => {handleShow(train.rid)}}> <p> Live tracker (stops) </p> </div>

                        <button className="button-dark" onClick={() => props.onTrainSelect(train.rid)}> 
                        {props.trainSelectText? props.trainSelectText : "Track this train"}
                        </button>
                    <span className="train-rid"> <p></p>   <p> {train.rid} </p> </span>
                    </div>

                )  
            )}
        </div>
        
        
        {props.trains.map( (train) => (
            <div key={train.rid} >
            <StopsModal show={show[train.rid]} onFooterClick={() => props.onTrainSelect(train.rid)} 
            footerText = {"Track This Train"}
            onHide={() => onHide(train.rid)} rid={train.rid} />
            </div>
        ))}    
    </>
 
    )
}

export default TrainList;
