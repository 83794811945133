
import React, {useState, useEffect} from "react"
import "./RippleLoader.css"


// https://codepen.io/rhulkashyap/pen/ONVPXb

function RippleLoader(props) {

     
    return (
        <div className="RippleLoader">
            <div className="signalbox-ripple"> </div>
        </div>
    )
}


export default RippleLoader;