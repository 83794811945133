// This modal shows information about the train including the stops. 

import React, { useState, useEffect, useRef } from 'react';
import Stops from 'common/Stops/Stops.js';
import { Dialog } from 'primereact/dialog';
import './StopsModal.css'
import ReactDOM from "react-dom";


// TODO make it so that it is also possible to parse the train information (rather than the rid) in the props. 
// This will allow train information to only be obtained once.  

function StopsModal(props) {

  const myRef = useRef(null)
  // const [displayBasic2, setDisplayBasic2] = useState(false);
  const [train, setTrain] = useState({});
  const [loading, setLoading] = useState(true); // when train is loading
  const [hasError, setHasError] = useState(false);

  let isSubscribed = false;

  useEffect(async ()=> {
    isSubscribed = true;
    const response = await fetch('/api/trains/' + props.rid )
    const train = await response.json();

    try{
        setTrain(train);
        setLoading(false);
    }catch{
      setHasError(true);
    }
  }, [])


  const getBodyContent = function(){
    if(loading){
      return (<p> Loading... </p> )
    }
    if(hasError){
      return <p> Something went wrong. </p>
    }
    return <Stops stops={train.stops}/>

  }
  // executed after the modal transitions in. 
  const onEntered = function(){
    // alert("onEntered")
    const timeout = setTimeout(() => {
      scrollToDot();// code to run after render goes here
    }, 20)
    document.getElementById("modal-body").style.visibility='visible';
  }

  const scrollToDot = function(){
    
     let locationDot = document.getElementById('nearest-stop');
     if(locationDot !== null){
      locationDot.scrollIntoView(true); 
     }
    
  }

  const header = (<div className="stops-title">
          <h3> {train.origin_name} to <br/>  
          {train.destination_name } </h3>
          <h4 > {train.train_operator} </h4>
          </div>)

  const footer = (
    <div className="stops-footer">
    <button className="button-dark" onClick={props.onFooterClick ? props.onFooterClick: props.onHide}> {props.footerText ? props.footerText : "Close"} </button>
    {/* <button className="button-dark" onClick={scrollToDot}> "Scroll to dot" </button> */}
   </div>
  )

  const stopsModal = (
    <Dialog className="StopsModal" header={header} footer={footer} visible={props.show}  
    onHide={props.onHide}
    position="bottom"
    blockScroll={true}
    dismissableMask={true}
    closable={true}
    transitionOptions={{onEntered:()=>{onEntered()}}}
    focusOnShow={false}
    >
          <div className="body" id="modal-body" style={{visibility:"hidden"}}>
            {getBodyContent()}
          </div>

      </Dialog>    
  );

  return ReactDOM.createPortal(stopsModal, document.querySelector("#root"));
}

export default StopsModal;
