// AKA ETA sharing (but also shows live train map)

import React, { useState, useEffect, useRef, useLayoutEffect} from 'react';
import { useParams, useLocation} from "react-router-dom"
import './TrainMap.css'
import EtaSharing from 'apps/share_this_train/EtaSharing/EtaSharing.js'
import qs from "querystringify";


import ReactMapGL, {Marker, ScaleControl} from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import TrainMarker from './train-marker.png'
import useTrainLocation from "common/useTrainLocation.js"; // custom hook
/* eslint import/no-webpack-loader-syntax: off */
import mapboxgl from 'mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import ErrorBoundary from 'common/ErrorBoundary.js'
import useTrackMarker from "common/useTrackMarker.js"; // custom hook

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
// This is to fix a bug when we deploy to heroku: https://stackoverflow.com/questions/45443274/webpack-2-expected-error-using-with-import/45447398#45447398

const MAPBOX_TOKEN = 'pk.eyJ1Ijoic2lnbmFsYm94IiwiYSI6ImNpeThza29oMjAwMTkyd3AzazYweGgxazgifQ.f7SV743q_ULYFNXeW7mc7g'
 
function TrainMap(props) {
  
  const { trainId, crs } = useParams(); 

  const [train, setTrain] = useState({});
  const [loading, setLoading] = useState(true);
  const [isError, setError] = useState(false);

  const [viewport, setViewport] = useState({
    width: 400,
    height: 400,
    latitude: 53.4871,
    longitude: -1.2424,
    zoom: 14
  });

  // effect to get train details
  useEffect(async ()=> {
    
    setLoading(true)
    try{
      let url = '/api/trains/' + trainId 
      const response = await fetch(url)
      const train = await response.json();
      if (!response.ok){
        throw(new Error())
      }
      setTrain(train)
    }catch{
      setError(true);
    }
    setLoading(false);
  }, [])

  const mapRef = useRef(null);
 
  // custom hook that retrieves location state of train with given rid. 
  const [location, error] = useTrainLocation(trainId)
  const map = mapRef.current ? mapRef.current.getMap() : null
  const targetViewport = useTrackMarker(location, map); // returns the target viewport for tracking the train

  useEffect(()=> {
      setViewport((viewport) => ({ 
        ...viewport, ...targetViewport
      }));
  }, [targetViewport])

  if(isError || error){
    return (
      <div className="TrainMap">
           <div className='central-div'>
            <h3>Unknown error</h3>
          </div>
      </div>
  )
  }
   
  if(loading){
    return (
        <div className="TrainMap">
            <div className='central-div'>
              <h3>Loading...</h3>
            </div>
        </div>
    )
  }
  return (
    <>
    <div className="TrainMap">
    
          {/* We put the mapbox component in an error handler because of the mapbox bug that 
          causes it to crash or iOS */}
        <ErrorBoundary key={'ABSKEH9K'}>

          <ReactMapGL
            {...viewport}
              onViewportChange={nextViewport => setViewport(nextViewport)}
              mapStyle="mapbox://styles/signalbox/ckuqviuao5p0s17o77cvykygz"
              width="100vw"
              height="100vh"
              mapboxApiAccessToken={MAPBOX_TOKEN}
              ref={map => mapRef.current = map}
              dragPan={true} 
              mapOptions={{logoPosition: 'top-right'}}
            >

            {Object.keys(location).length !== 0 ? 

              <Marker latitude={location.location.lat} longitude={location.location.lon}
                offset={'[0 0]'} >
                <div className='train-marker'>
                  <img src={TrainMarker}/>
                  </div>
              </Marker>
              : null
            }
          </ReactMapGL>

        </ErrorBoundary>

          {crs ? <EtaSharing train={train} crs={crs}/> : null}

    </div>

    </>
  );
}

export default TrainMap;
