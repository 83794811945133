import React, { useState } from "react"
import { useHistory, withRouter} from 'react-router-dom';
import './ShareThisTrain.css'
// import Detect from "./Detect.js"
import DestinationStation from "apps/share_this_train/DestinationStation/DestinationStation.js"
import useSignalbox from "common/useSignalbox.js";
import Header from "common/Header/Header.js"
import TrainList from "common/TrainList/TrainList.js"
import NoResult from "common/NoResult/NoResult.js"
import TrainMap from "apps/share_this_train/TrainMap/TrainMap.js"


import {
  Router, // needed because of this https://reactforyou.com/history-push-is-only-changing-the-url-not-rendering-component-react-fix/
  Switch,
  Route,
  useRouteMatch,
  Redirect
} from "react-router-dom";



function ShareThisTrain(props) {
  
  let history = useHistory();
  let { path, url } = useRouteMatch();
  const [destination, setDestination] = useState('yvj')
  const [errorMessage, setErrorMessage] = useState(null)

  const onCompleted = function(trains){
    if (trains.length === 0){
      history.push('/share-this-train/no-train');
    }
    if (trains.length === 1){
      history.push('/share-this-train/trains/' + trains[0].rid + '/' + destination);
    }
    if (trains.length >= 2){
      history.push("/share-this-train/train-list");
    }
  }

  const onError = function(message){
    // alert(message);
    setErrorMessage(message);
    history.push(`${path}/error`);
  }
  
  const [trains, detecting, isError, startFetch, stopFetch, setError] = useSignalbox(onCompleted, onError)

  const returnHome = () => {
    stopFetch();
    setError(false); // have to reset error
    history.push(`${path}`);
  }

  const backToResults = () => {
    stopFetch();
    history.push(`${path}/train-list`);
  }

  const startSignalbox = (destination) =>{
    startFetch(destination);
  }

  const onTrainSelect = function(rid){
        history.push(`${path}/trains/${rid}/${destination}`);
    }

    return (

      <div className="ShareThisTrain"> 

    
        <Router history={history}>
          <Switch>

            <Route exact path={`${path}/error`}>
                <Header onBackClick={returnHome} homeFormat={!detecting} backText="Back to search"/>
                <NoResult onTryAgainClick={returnHome} message={errorMessage}/>
            </Route>

            <Route exact path={`${path}/`}>
                <Header onBackClick={returnHome} homeFormat={!detecting} backText="Back to search"/>
                <DestinationStation startSignalbox={startSignalbox} detecting={detecting} isError={isError}
                setDestination = {setDestination}
                /> 
            </Route>

            <Route exact path={`${path}/no-train`}>
              <Header onBackClick={returnHome} homeFormat={false} backText="Back to search"/>
              <NoResult onTryAgainClick={returnHome}/>
            </Route>

            <Route exact path={`${path}/train-list`}>
              <Header onBackClick={returnHome} homeFormat={false} backText="Back to search" style={{backgroundColor: "#F6F6F6"}}/>
              {trains.length == 0 ? <Redirect to={`${path}/`} />:  <TrainList trains={trains} onTrainSelect={onTrainSelect} />}
            </Route>
              
            <Route exact path={`${path}/trains/:trainId/:crs`}>
              <Header onBackClick={backToResults} home={true} backText="Back to previous"/>
              <TrainMap />
            </Route> 

          </Switch>

      </Router>

    </div>

    )
  }

export default withRouter(ShareThisTrain);